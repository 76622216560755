<template>
  <div class="popup wide">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Золотая АЗС</h4>

    <div class="modal-remove" v-if="removeDate !== null">
      <div class="modal-remove__overlay" @click="removeDate = null"></div>
      <div class="modal-remove__content">
        <p>Вы действительно хотите удалить скидки за {{ removeDate }}?</p>

        <div class="actions">
          <button class="button btn-primary" @click="removeDate = null">Нет</button>
          <button class="button btn-primary" @click="setRemove()">Да</button>
        </div>
      </div>
    </div>

    <div class="discount-block">
      <ul>
        <li>
          <button class="btn-primary export-btn" @click="setNew = true;setNewDate()">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Добавить дату
          </button>
        </li>
        <li v-for="(date, index) of datesList">
          <button class="discount-block__btn-date" @click="setItem(date, index);setNew = false" :class="{ 'active-date': date.active, 'restricted-date': date.arr.some(item => item.restricted) }">
            {{ date.name }}
            <button :disabled="date.arr.some(item => item.restricted)" @click.stop="removeItem(date)">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </button>
        </li>
      </ul>
      <div class="form">
        <div class="date-picker">
          <div class="wrap">
            <span class="date-picker-label">Выбора даты</span>

            <VueDatePicker
              ref="dateRange"
              v-model="date"
              :enable-time-picker="false"
              :format="'dd.MM.yyyy'"
              locale="ru"
              cancelText="Отмена"
              selectText="Выбрать"
              :clearable="false"
              :teleport="true"
              :disabled="!setNew"
              @update:model-value="formatingDate()"
            >
              <template #input-icon>
                <img class="input-slot-image" src="../../assets/datepicker-icon.svg" alt="d"/>
              </template>
            </VueDatePicker>
          </div>
        </div>

        <button :disabled="disableEdit || setAllProviders" class="btn-primary export-btn" @click="setAllProviders = true">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px">
            <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Установить скидку на весь ВИНК
        </button>
        <div v-if="setAllProviders" class="discount-item">
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <v-select :options="goldFuelProvidersReq"
                        label="name"
                        v-model="fuel_provider_id"
                        :clearable="false"
                        :reduce="item => item.fuel_provider_id"
                        :searchable="true"
                        :appendToBody="true"
                        :disabled="disableEdit"
              ></v-select>
            </div>
          </div>
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <span>ДТ</span>
              <input type="number" v-model="fuel_provider_id_dt"
                     :disabled="disableEdit">
            </div>
            <button :disabled="disableEdit" class="percent-toggle" :class="{'active': fuel_provider_id_is_percent === true}" @click="fuel_provider_id_is_percent = true">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 3.75L3.75 14.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button :disabled="disableEdit" class="percent-toggle currency" :class="{'active': fuel_provider_id_is_percent === false}" @click="fuel_provider_id_is_percent = false">
              ₽
            </button>
          </div>
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <span>ДТ-З</span>
              <input type="number" v-model="fuel_provider_id_dt_winter"
                     :disabled="disableEdit">
            </div>
            <button :disabled="disableEdit" class="percent-toggle" :class="{'active': fuel_provider_id_is_percent_winter === true}" @click="fuel_provider_id_is_percent_winter = true">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 3.75L3.75 14.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button :disabled="disableEdit" class="percent-toggle currency" :class="{'active': fuel_provider_id_is_percent_winter === false}" @click="fuel_provider_id_is_percent_winter = false">
              ₽
            </button>
          </div>
          <button
            class="references-item__btn"
            :disabled="fuel_provider_id === null"
            @click="setAllProvidersFunc()"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
              d="M3.5 9.50045L7.21231 13.2128L15.1673 5.25781"
              stroke="#6DB534"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          </button>
          <button
            class="references-item__btn references-item__btn-cancel"
            @click="setAllProviders = false"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 14L5 5"
                stroke="#FF0000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 5L5 14"
                stroke="#FF0000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <hr class="line">

        <div class="discount-item" v-for="(item, index) of discountsList">
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <v-select :options="goldStations"
                        label="numberAddress"
                        v-model="item.gold_station_id"
                        :clearable="false"
                        :reduce="item => item.id"
                        :searchable="true"
                        :appendToBody="true"
                        :disabled="disableEdit"
              ></v-select>
            </div>
          </div>
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <span>ДТ</span>
              <input type="number" v-model="item.value"
                     :disabled="disableEdit">
            </div>
            <button :disabled="disableEdit" class="percent-toggle" :class="{'active': item.is_percent === true}" @click="setItemPercent(index, 'is_percent', true)">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 3.75L3.75 14.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button :disabled="disableEdit" class="percent-toggle currency" :class="{'active': item.is_percent === false}" @click="setItemPercent(index, 'is_percent', false)">
              ₽
            </button>
          </div>
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <span>ДТ-З</span>
              <input type="number" v-model="item.value_winter"
                     :disabled="disableEdit">
            </div>
            <button :disabled="disableEdit" class="percent-toggle" :class="{'active': item.is_percent_winter === true}" @click="setItemPercent(index, 'is_percent_winter', true)">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path d="M14.25 3.75L3.75 14.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.875 6.75C5.91053 6.75 6.75 5.91053 6.75 4.875C6.75 3.83947 5.91053 3 4.875 3C3.83947 3 3 3.83947 3 4.875C3 5.91053 3.83947 6.75 4.875 6.75Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.125 15C14.1605 15 15 14.1605 15 13.125C15 12.0895 14.1605 11.25 13.125 11.25C12.0895 11.25 11.25 12.0895 11.25 13.125C11.25 14.1605 12.0895 15 13.125 15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
            <button :disabled="disableEdit" class="percent-toggle currency" :class="{'active': item.is_percent_winter === false}" @click="setItemPercent(index, 'is_percent_winter', false)">
              ₽
            </button>
          </div>
          <button @click="removeItemDiscount(index)"
                  :disabled="disableEdit">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <button :disabled="disableEdit" class="btn-primary export-btn" @click="addItemDiscount()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px">
            <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Добавить скидку
        </button>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button :disabled="disableEdit" class="button btn-primary" @click="saveDiscount()">Сохранить</button>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader.vue'
import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";

export default {
  name: "setDiscountGold",
  components: {vSelect, VueDatePicker, Loader},
  mixins: [popups],
  data: () => ({
    loading: false,
    discountsList: [],
    datesList: [],
    date: null,
    dateModel: null,
    setNew: true,
    removeDate: null,
    setAllProviders: false,
    goldFuelProvidersReq: [],
    fuel_provider_id: null,
    fuel_provider_id_dt: '',
    fuel_provider_id_is_percent: false,
    fuel_provider_id_dt_winter: '',
    fuel_provider_id_is_percent_winter: true
  }),
  computed: {
    ...mapState({
      goldStations: state => state.admin.goldStations,
      goldGas: state => state.customer.goldGas,
      customer: state => state.popups.customer,
      discountBlockDay: (state) => state.customer.discountBlockDay,
      goldFuelProviders: (state) => state.admin.goldFuelProviders,
      fuelProviders: (state) => state.user.fuelProviders,
    }),
    disableEdit() {
      return !this.setNew && this.datesList.find(date => date.active)?.arr.some(item => item.restricted)
    }
  },
  methods: {
    ...mapActions({
      getGoldStations: 'getGoldStations',
      getClientGoldGas: 'getClientGoldGas',
      setClientGoldGas: 'setClientGoldGas',
      removeClientGoldGas: 'removeClientGoldGas',
      getGoldFuelProviders: 'getGoldFuelProviders',
      getFuelProviders: 'getFuelProviders',
    }),
    removeItemDiscount(index) {
      this.discountsList.splice(index, 1)
    },
    addItemDiscount() {
      this.discountsList.push({
        gold_station_id: null,
        value: '',
        is_percent: false,
        value_winter: '',
        is_percent_winter: true
      })
    },
    formatingDate() {
      this.dateModel = `${this.date.getFullYear()}-${String(this.date.getMonth() + 1).padStart(2, '0')}-${String(this.date.getDate()).padStart(2, '0')}`;
    },
    setAllProvidersFunc() {
      if (this.discountsList.length === 1 && this.discountsList[0].value === '' && this.discountsList[0].value_winter === '') {
        this.discountsList = []
      }
      let discountsListFiltered = this.discountsList.filter(item => {
        let goldStationObj = this.goldStations.find(station => station.id === item.gold_station_id)
        let goldStationFuelProviderId = goldStationObj.fuel_provider_id
        return goldStationFuelProviderId !== this.fuel_provider_id
      })
      this.discountsList = discountsListFiltered;
      this.goldStations.forEach(item => {
        if (item.fuel_provider_id === this.fuel_provider_id) {
          this.discountsList.push({
            gold_station_id: item.id,
            value: this.fuel_provider_id_dt,
            is_percent: this.fuel_provider_id_is_percent,
            value_winter: this.fuel_provider_id_dt_winter,
            is_percent_winter: this.fuel_provider_id_is_percent_winter
          })
        }
      })
      this.setAllProviders = false;
      this.fuel_provider_id = null;
      this.fuel_provider_id_dt = '';
      this.fuel_provider_id_is_percent = false;
      this.fuel_provider_id_dt_winter = '';
      this.fuel_provider_id_is_percent_winter = true;
    },
    saveDiscount() {
      this.loading = true
      console.log('saveDiscount', this.discountsList)
      let data = {
        start_date: this.dateModel,
        data: []
      };
      if (!this.setNew) {
        let dateSplit = this.date.split('.')
        data.start_date = `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`;
      }
      this.discountsList.forEach(item => {
        if (item.value !== null && item.value !== '') {
          data.data.push({
            gold_station_id: item.gold_station_id,
            //id: item.gold_station_id,
            //region_id: item.region_id,
            value: item.value,
            is_percent: item.is_percent,
            discount_winter: item.value_winter,
            is_percent_winter: item.is_percent_winter
          })
        }
      })
      this.setClientGoldGas({customerID: this.customer.id, data})
      this.loading = false
    },
    setItemPercent(index, key, value) {
      console.log(this.discountsList[index][key])
      this.discountsList[index][key] = value
    },
    setNewDate() {
      this.date = null;
      this.discountsList = [{
        gold_station_id: null,
        //region_id: null,
        value: '',
        is_percent: false,
        value_winter: '',
        is_percent_winter: true
      }]
    },
    setItem(data, index) {
      this.datesList.forEach((item, i) => {
        item.active = index === i;
      })
      let dateSplit = data.name.split('.')
      this.date = `${dateSplit[2]}.${dateSplit[1]}.${dateSplit[0]}`;
      this.discountsList.forEach(discount => {
        discount.value = '';
      })
      this.discountsList = []
      data.arr.forEach(item => {
        this.discountsList.push({
          gold_station_id: item.gold_station_id,
          //region_id: item.region_id,
          //value: item.discount
          value: item.price,
          is_percent: item.is_percent,
          value_winter: item.discount_winter,
          is_percent_winter: item.is_percent_winter
        })
      })
    },
    removeItem(date) {
      this.removeDate = date.name
    },
    setRemove() {
      let dateSplit = this.removeDate.split('.')
      let data = {
        customerID: this.customer.id,
        start_date: `${dateSplit[0]}-${dateSplit[1]}-${dateSplit[2]}`,
      }
      this.removeClientGoldGas(data).then(res => {
        this.setItems();
      });
      this.removeDate = null;
    },
    async setItems() {
      await this.getGoldStations()
      this.getClientGoldGas(this.customer.id).then(res => {
        this.discountsList = [{
          gold_station_id: null,
          value: '',
          is_percent: false,
          value_winter: '',
          is_percent_winter: true
        }];
        
        const checkedGoldGas = this.checkRestrictions(this.goldGas);
        
        let arr = [];
        Object.keys(checkedGoldGas).map((key) => {
          let keySplit = key.split('-')
          let date = `${keySplit[2]}.${keySplit[1]}.${keySplit[0]}`
          arr.push({
            name: date,
            arr: checkedGoldGas[key],
            active: false
          })
        })
        this.datesList = arr;
      })
    },
    checkRestrictions(goldGasData) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      
      const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;
      const prevYear = currentMonth === 0 ? currentYear - 1 : currentYear;
      
      Object.keys(goldGasData).forEach(key => {
        goldGasData[key].forEach(item => {
          let dateTransaction = new Date(item.start_date)
          let dateTransactionUTC = new Date(dateTransaction.getFullYear(), dateTransaction.getMonth(), dateTransaction.getDate(), 0, 0, 0)
          let dateBlock = new Date(this.discountBlockDay)
          let dateBlockUTC = new Date(dateBlock.getFullYear(), dateBlock.getMonth(), dateBlock.getDate(), 0, 0, 0)
          item.restricted = dateTransactionUTC.getTime() <= dateBlockUTC.getTime()
        });
      });
      
      return goldGasData;
    }
  },
  async mounted() {
    await this.getGoldFuelProviders();
    await this.getFuelProviders();
    this.goldFuelProviders.forEach(item => {
      this.fuelProviders.forEach(item2 => {
        if (item.fuel_provider_id === item2.id) {
          this.goldFuelProvidersReq.push({
            id: item.id,
            name: item2.name,
            fuel_provider_id: item.fuel_provider_id
          })
        }
      })
    })
    this.setItems();
  }
}
</script>

<style lang="scss">
.form__item {
  .vs__dropdown-toggle {
    overflow: hidden;
  }
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";

.line {
  margin: 10px 0 10px;

  &__last {
    margin: 25px 0 0;
  }
}

.references-item__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin: 0 0 10px 0;
  padding: 0;
  border: none;
  border-radius: 4px;
  background: none;
  transition: all ease 0.3s;

  &:hover {
    background: rgba(109, 181, 52, 0.12);
  }
}

.references-item__btn-cancel {
  &:hover {
    background: rgba(255, 0, 0, 0.12) !important;
  }
}

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.left, .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.wide {
  max-width: unset;
  width: auto;
  max-height: 80%;

  .form {
    flex-direction: unset;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 800px;
    max-height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;
    gap: 6px;
    @media screen and (max-width: 1100px) {
      width: 100%;
      max-height: calc(80vh - 400px);
    }
  }
}

.discount-block {
  display: flex;

  & > ul {
    min-width: 170px;
    margin: 0 20px 0 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
      margin-bottom: 5px;

      &:first-child {
        margin-bottom: 20px;

        button {
          justify-content: flex-start;
          svg {
            margin-right: 10px;
          }
        }
      }

      & > button {
        width: 100%;
      }
    }
  }

  &__btn-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #F1F8EB;
    background: none;
    text-align: left;
    transition: all ease .3s;

    &:hover {
      background: #F1F8EB;
    }

    &.active-date {
      background: #F1F8EB;
    }

    &.restricted-date {
      opacity: 0.7;
      filter: grayscale(1);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 3px 4px 3px 3px;
      background: none;
      border: none;
      border-radius: 6px;
      transition: all ease .3s;

      &:hover {
        background: #85ff8e;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    & > ul {
      margin-right: 0;
    }
  }
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  align-self: stretch;

  .date-picker-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 40%;
    padding: 0 10px;
  }


  .wrap {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: $grey-2;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    text-align: left;

    .dp__input {
      border-radius: 6px;
      border: 1px solid $back !important;
      --dp-border-color: $back !important;
    }
  }
}

.modal-remove {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 11;

  &__overlay {
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0.3;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    transition: none!important;
    transform: none!important;
  }

  &__content {
    position: relative;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(109, 181, 52, 0.30);
    padding: 30px;
    z-index: 10;
  }
}

.discount-item {
  display: flex;
  //align-items: center;
  width: 100%;

  .form__item {
    width: calc((100% - 4px - 25px) / 3);
    gap: 0;
    margin-left: 2px;
    .label {
      width: 0;
      padding: 0;
    }

    .input {
      position: relative;
      //width: 100%;
      width: calc(100% - 76px);
      box-sizing: border-box;
      background: transparent;

      & > span {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        font-size: 12px;
      }

      input {
        display: flex;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        padding-left: 55px;
        border-radius: 6px 0 0 6px !important;
        height: 43px;
        box-sizing: border-box;
        background: transparent;
        text-align: right;
        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    &:nth-child(4) {
      width: 75px;
    }
    .percent-toggle {
      width: 38px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 0px;
      border: none;
      background: #F1F8EB;

      color: #6DB534;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      //font-family: "SF Pro Text";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 80% */

      &.currency {
        border-radius: 0px 6px 6px 0px;
      }

      svg {
        path {
          stroke: #6DB534;
        }
      }

      &.active {
        background: #6DB534;
        color: #fff;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin: 0;
    margin-top: 8px;
    padding: 3px 4px 3px 3px;
    background: none;
    border: none;
    border-radius: 6px;
    transition: all ease .3s;

    &:hover {
      background: #85ff8e;
    }
  }

  button:disabled,
  input:disabled,
  .v-select.vs--disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
</style>
