<template>
  <div class="popup">
    <Loader v-if="loading" />
    <h4 class="popup__title middle">Активные ВИНКи для золотых АЗС</h4>

    <div class="modal-remove" v-if="removeFlag">
      <div class="modal-remove__overlay" @click="removeFlag = false"></div>
      <div class="modal-remove__content">
        <p>Вы действительно хотите удалить ВИНК из активных?</p>

        <div class="actions">
          <button class="button btn-primary" @click="removeFlag = false">Нет</button>
          <button class="button btn-primary" @click="setRemove()">Да</button>
        </div>
      </div>
    </div>

    <div class="discount-block">
      <div class="form">
        <div class="discount-item" v-for="(item, index) of goldFuelProvidersReq">
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <v-select :options="goldFuelProvidersReq"
                        v-model="item.id"
                        :reduce="item => item.id"
                        label="name"
                        :clearable="false"
                        :disabled="true"
              ></v-select>
            </div>
          </div>
          <button @click="removeItemDiscount('remove', item.id)">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="discount-item" v-for="(item, index) of goldFuelProvidersList">
          <div class="form__item">
            <div class="label">
            </div>
            <div class="input">
              <v-select :options="fuelProvidersArr"
                        label="name"
                        v-model="item.fuel_provider_id"
                        :clearable="false"
                        :reduce="item => item.fuel_provider_id"
                        :searchable="false"
                        :appendToBody="true"
              ></v-select>
            </div>
          </div>
          <button @click="removeItemDiscount('splice', index)">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.42857 5.25V3.6C6.42857 3.24196 6.56403 2.89858 6.80515 2.64541C7.04627 2.39223 7.37329 2.25 7.71429 2.25H10.2857C10.6267 2.25 10.9537 2.39223 11.1949 2.64541C11.436 2.89858 11.5714 3.24196 11.5714 3.6V5.25M14.25 5.25L13.5 14.4C13.5 14.758 13.3645 15.1014 13.1234 15.3546C12.8823 15.6078 12.5553 15.75 12.2143 15.75H5.78571C5.44472 15.75 5.1177 15.6078 4.87658 15.3546C4.63546 15.1014 4.5 14.758 4.5 14.4L3.75 5.25H14.25Z" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3 5.25H4.33333H15" stroke="#6DB534" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <button class="btn-primary export-btn" @click="addItemDiscount()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-right: 10px">
            <path d="M12.25 7H1.75" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7 1.75V12.25" stroke="#6DB534" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Добавить ВИНК
        </button>
      </div>
    </div>

    <div class="actions">
      <button class="button btn-primary" @click="closeAll()">Отмена</button>
      <button class="button btn-primary" @click="saveDiscount()">Сохранить</button>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader.vue'
import {popups} from '@/mixins/popups'
import {mapActions, mapState} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";

export default {
  name: "setGoldFuelProviders",
  components: {vSelect, VueDatePicker, Loader},
  mixins: [popups],
  data: () => ({
    loading: false,
    goldFuelProvidersList: [],
    goldFuelProvidersReq: [],
    fuelProvidersArrDef: [],
    fuelProvidersArr: [],
    removeFlag: false,
    removeId: null
  }),
  computed: {
    ...mapState({
      goldFuelProviders: (state) => state.admin.goldFuelProviders,
      fuelProviders: (state) => state.user.fuelProviders,
    })
  },
  methods: {
    ...mapActions({
      setGoldFuelProviders: 'setGoldFuelProviders',
      removeGoldFuelProviders: 'removeGoldFuelProviders',
      getGoldFuelProviders: 'getGoldFuelProviders',
      getFuelProviders: 'getFuelProviders',
    }),
    removeItemDiscount(flag, id) {
      if (flag === 'remove') {
        this.removeFlag = true;
        this.removeId = id;
      }
      if (flag === 'splice') {
        this.goldFuelProvidersList.splice(id, 1)
      }
    },
    addItemDiscount() {
      this.goldFuelProvidersList.push({})
    },
    saveDiscount() {
      this.loading = true
      this.goldFuelProvidersList.forEach(async item => {
        await this.setGoldFuelProviders({fuel_provider_id: item.fuel_provider_id});
      })
      setTimeout(() => {
        this.setItems();
      }, 1000)
    },
    async setRemove() {
      this.loading = true
      await this.removeGoldFuelProviders(this.removeId)
      setTimeout(() => {
        this.setItems();
      }, 1000)
      this.removeId = null
      this.removeFlag = false;
    },
    async setItems() {
      this.goldFuelProvidersList = []
      this.goldFuelProvidersReq = []
      this.fuelProvidersArrDef = []
      this.fuelProvidersArr = []
      await this.getGoldFuelProviders();
      await this.getFuelProviders();
      this.fuelProviders.forEach(item => {
        this.fuelProvidersArrDef.push({fuel_provider_id: item.id, name: item.name})
        this.fuelProvidersArr.push({fuel_provider_id: item.id, name: item.name})
      })
      this.goldFuelProviders.forEach(item => {
        this.fuelProvidersArr.forEach((item3, index) => {
          if (item.fuel_provider_id === item3.fuel_provider_id) {
            this.fuelProvidersArr.splice(index, 1)
          }
        })
        this.fuelProvidersArrDef.forEach(item2 => {
          if (item.fuel_provider_id === item2.fuel_provider_id) {
            this.goldFuelProvidersReq.push({
              id: item.id,
              name: item2.name,
              fuel_provider_id: item.fuel_provider_id
            })
          }
        })
      })
      this.loading = false
    },
  },
  mounted() {
    this.loading = true
    this.setItems();
  }
}
</script>

<style lang="scss">
.form__item {
  .vs__dropdown-toggle {
    overflow: hidden;
  }
}
</style>

<style scoped lang="scss">
@import "../../styles/variables";

.export-btn {
  width: 100%;
  margin-top: 15px;
}

.popup {
  :deep(.loader) {
    height: 200%;
    width: 200%;
    left: -50%;
    top: -50%;
    border-radius: 25px;
    transform: scale(0.5);
    svg {
      position: unset;
    }
  }
}

.left, .right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
}

.wide {
  max-width: unset;
  width: auto;
  max-height: 80%;

  .form {
    flex-direction: unset;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 560px;
    max-height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;
    gap: 6px;
    @media screen and (max-width: 700px) {
      width: 100%;
      max-height: calc(80vh - 400px);
    }
  }
}

.discount-block {
  display: flex;

  & > ul {
    min-width: 170px;
    margin: 0 20px 0 0;
    padding: 0;
    list-style: none;

    li {
      width: 100%;
      margin-bottom: 5px;

      &:first-child {
        margin-bottom: 20px;

        button {
          justify-content: flex-start;
          svg {
            margin-right: 10px;
          }
        }
      }

      & > button {
        width: 100%;
      }
    }
  }

  &__btn-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #F1F8EB;
    background: none;
    text-align: left;
    transition: all ease .3s;

    &:hover {
      background: #F1F8EB;
    }

    &.active-date {
      background: #F1F8EB;
    }

    &.restricted-date {
      opacity: 0.7;
      filter: grayscale(1);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 3px 4px 3px 3px;
      background: none;
      border: none;
      border-radius: 6px;
      transition: all ease .3s;

      &:hover {
        background: #85ff8e;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    width: 100%;
    & > ul {
      margin-right: 0;
    }
  }
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  align-self: stretch;

  .date-picker-label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 40%;
    padding: 0 10px;
  }


  .wrap {
    gap: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: $grey-2;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: SF_Pro_Medium, Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    text-align: left;

    .dp__input {
      border-radius: 6px;
      border: 1px solid $back !important;
      --dp-border-color: $back !important;
    }
  }
}

.modal-remove {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 11;

  &__overlay {
    width: 100%;
    height: 100%;
    background: $black;
    opacity: 0.3;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    transition: none!important;
    transform: none!important;
  }

  &__content {
    position: relative;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(109, 181, 52, 0.30);
    padding: 30px;
    z-index: 10;
  }
}

.discount-item {
  display: flex;
  //align-items: center;
  width: 100%;

  .form__item {
    width: 100%;
    gap: 0;
    margin-left: 2px;
    .label {
      width: 0;
      padding: 0;
    }

    .input {
      position: relative;
      //width: 100%;
      width: calc(100% - 76px);
      box-sizing: border-box;
      background: transparent;

      & > span {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        font-size: 12px;
      }

      input {
        display: flex;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        align-self: stretch;
        padding-left: 55px;
        border-radius: 6px 0 0 6px !important;
        height: 43px;
        box-sizing: border-box;
        background: transparent;
        text-align: right;
        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    &:nth-child(4) {
      width: 75px;
    }
    .percent-toggle {
      width: 38px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 0px;
      border: none;
      background: #F1F8EB;

      color: #6DB534;
      text-align: center;
      font-feature-settings: 'liga' off, 'clig' off;
      //font-family: "SF Pro Text";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 80% */

      &.currency {
        border-radius: 0px 6px 6px 0px;
      }

      svg {
        path {
          stroke: #6DB534;
        }
      }

      &.active {
        background: #6DB534;
        color: #fff;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin: 0;
    margin-top: 8px;
    padding: 3px 4px 3px 3px;
    background: none;
    border: none;
    border-radius: 6px;
    transition: all ease .3s;

    &:hover {
      background: #85ff8e;
    }
  }

  button:disabled,
  input:disabled,
  .v-select.vs--disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
</style>
